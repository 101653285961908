import React from 'react'
import ConsultingTemplate from 'components/templates/ConsultingTemplate/ConsultingTemplate'

export default function ConsultingIso9001Page() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'ISO 9001'

  const description =
    'La qualità del prodotto finale è garantita solo mediante il controllo delle singole fasi che compongono il processo produttivo.<br> Un sistema qualità ISO 9001 prevede adeguate soluzioni per soddisfare tale principio; esso è incentrato su un manuale della qualità e da una serie di procedure gestionali che descrivono le modalità con le quali l’azienda intende soddisfare i requisiti applicabili.<br> La certificazione è rilasciata a seguito di esito positivo di una verifica ispettiva comprendente una valutazione formale della rispondenza della documentazione di sistema ai requisiti della norma (esame documentale) e una visita in campo, nella quale si verifica che le modalità con le quali l’impresa ha deciso di soddisfare i singoli requisiti (approvati in sede di esame documentale) siano effettivamente applicati.<br><br> <b>L’azienda ne ricava ovvi vantaggi in termini di:</b> <br><br>– Resa organizzativa;<br> – Resa produttiva;<br> – Gestione delle risorse tecnologiche ed umane;<br> – Eliminazione dei costi della non qualità;<br> – Soddisfazione del Cliente (Progettazione e gestione CUSTOMER SERVICE e servizi collegati);<br> – Valutazione dell’immagine aziendale<br> – Soddisfazione dei Dipendenti (clima aziendale partecipativo e motivante);<br> – Soddisfazione Soci/Proprietari/Azionisti (proficua gestione);<br> – Adeguamento a normative di settore (Appalti, Sanità, Edilizia, Servizi)<br><br> <b>Qualità è tanto altro ancora<br><br> I 5 perché scegliere un Sistema di Gestione Qualità conforme alla ISO 9001:</b><br><br> – Gare D’appalto<br> – Nuovi Clienti<br> – Nuovi Mercati<br> – Visibilità di Mercato<br> – Gestione e Controllo Fornitori'

  return (
    <>
      <ConsultingTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
